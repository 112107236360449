const MAX_FIX_FEE_VALUE = 20000;

export function calculateStampFee(amount) {
  if (amount >= 70 && amount <= 150) {
    return 1.49;
  } else if (amount > 150 && amount <= 300) {
    return 3.99;
  } else if (amount > 300 && amount <= 500) {
    return 9.99;
  } else if (amount > 500 && amount <= 2000) {
    return 19.99;
  } else if (amount > 2000 && amount <= 5000) {
    return 24.99;
  } else if (amount > 5000 && amount <= 10000) {
    return 39.99;
  } else if (amount > 10000 && amount <= MAX_FIX_FEE_VALUE) {
    return 49.99;
  } else if (amount > MAX_FIX_FEE_VALUE) {
    return amount * 0.01; // 1% of the invoice amount
  } else {
    return 0;
  }
}
